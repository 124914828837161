function bootCalendar(){
    $('.Calendar__day').hover(
        function(){
            $(this).find('.Calendar__day__info').fadeIn(150);
        },
        function(){
            $(this).find('.Calendar__day__info').fadeOut(150);
        }
    );
}
function getMonth(m,y){
    $('#calendarContainer').load("http://perilrefettorio.it/getCalendar/" + m + "/" + y, function(){
        bootCalendar();
    });
}

function getList(m,y){
    $('#calendarContainer').load("http://perilrefettorio.it/getCalendar/" + m + "/" + y + "/true");
}
