function bootVideoGallery(){
    $('.video').click(function(){
        var id = $(this).attr('id');
        $('#viewer_wrapper').slideUp(200, function(){
            $(this).css('padding','10px 30px');
            $(this).load('http://perilrefettorio.it/getVideoDetail/' + id, function(){$('#viewer_wrapper').slideDown(
                300, function(){
                    $('.close').click(function(){
                        $('#viewer_wrapper').slideUp();
                    });
                }
            )});
        });
    });
}