function fixMenu(){
    var menuWidth = $('#menu').outerWidth();

    var items = $('#menu').find('li');
    if(menuWidth>500){
        var tot = 0;
        items.each(function(i){
            tot += $(this).width();
        });
        var pad = (menuWidth-tot)/items.length;
        items.each(function(){
            $(this).css('padding', '15px ' + Math.floor((pad/2)) + 'px');
        });
    }else{
        items.each(function(){
            $(this)
                .css('float', 'none')
                .css('padding', '0');
        });
    }
}//
function bootMenu(){
    //fixMenu();
    //$(window).resize(function(){
    //    fixMenu();
    //});
}